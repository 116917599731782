import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, Button, CardTitle, CardSubtitle, CardText, Input, CardHeader } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { useHistory } from 'react-router-dom'

import SweetAlert from 'sweetalert2'
import StripeService from '../../../network/services/stripe'
import UserService from '../../../network/services/user'



const VeDiscordSubscription = ({ subscribedDiscord, unsubscribeDiscordList }) => {

    // Subscribe trade idea
    const filteredSimmiIdeaSubscribed = subscribedDiscord.filter(idea => {
        return idea.discord_group_id == 2 && idea.status == '1'
    })

    const filteredVernonIdeaSubscribed = subscribedDiscord.filter(idea => {
        return idea.discord_group_id == 1 && idea.status == '1'
    })

    // Unsubscribe but still in group's trade idea
    const filteredSimmiIdeaUnSubscribe = unsubscribeDiscordList.filter(idea => {
        return idea.discord_group_id == 2 && idea.status == '3'
    })

    const filteredVernonIdeaUnSubscribe = unsubscribeDiscordList.filter(idea => {
        return idea.discord_group_id == 1 && idea.status == '3'
    })

    const { t } = useTranslation()
    const { register, handleSubmit, errors, formState } = useForm()
    const [isBusy, setIsBusy] = useState(false)
    const [isMember, setIsMember] = useState(false)

    const fetchIsMember = async () => {
        try {
            const resultMembers = await UserService.getMyselfMembers()
            setIsMember(resultMembers.members)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchIsMember()
    }, [])
   
    let history = useHistory()
    const dispatch = useDispatch()
    const navigate = (path) => {
        history.push(path)
        dispatch({ type: 'SET_ACTIVE_MENU', path: path })
    }
   

    const unsubscribeDiscord = (discordID) => {
        SweetAlert.fire({
            showClass: {
                popup: 'animate__animated animate__backInUp'
            },
            hideClass: {
                popup: 'animate__animated animate__backOutDown'
            },
            title: 'Are you sure you want to unsubscribe?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Unsubscribe',
            cancelButtonText: t('financial.cancel'),
            reverseButtons: true
        }).then(async (result) => {
            setIsBusy(false)
            if (result.value) {
                try {
                    let result = await StripeService.unsubscribeSubscription(discordID)

                    if (result) {
                        SweetAlert.fire({
                            showClass: {
                                popup: 'animate__animated animate__backInUp'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__backOutDown'
                            },
                            title: 'Unsubscribed Successfully',
                            icon: 'success',
                        }).then(() => {
                            window.location.reload()
                        })
                    }
                } catch (error) {
                    setIsBusy(false)
                    const errorCode = error.code
                    const errorTranslate = error.translate_params


                    SweetAlert.fire({
                        showClass: {
                            popup: 'animate__animated animate__backInUp'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__backOutDown'
                        },
                        title: t(errorCode, errorTranslate),
                        icon: 'error'
                    })
                }
            }
        })

    }

    return <>
        <Row>
            {subscribedDiscord.map(discordIdea => {
                return <Col lg='6'>
                    <Card>
                        <Row className='align-items-center'>
                            <Col lg='7'>
                                <CardBody>
                                    <CardTitle><h3>{discordIdea.discordGroup.name}</h3></CardTitle>
                                    {/* <CardSubtitle>Status: {checkStatus(discordIdea.status)}</CardSubtitle> */}
                                    <CardSubtitle>${isMember ? discordIdea.discordGroup.member_price : discordIdea.discordGroup.non_member_price } next billing cycle</CardSubtitle>
                                    <CardText>Status: Active</CardText>
                                    <CardText>Your card will be charged on {moment(discordIdea.next_billing_date).format('yyyy-MM-DD')}</CardText>
                                    <a style={{ cursor: 'pointer', color: '#C4A569' }} onClick={() => {
                                        unsubscribeDiscord(discordIdea.id)
                                    }} ><u>Cancel Subscription</u></a>
                                </CardBody>
                            </Col>
                            <Col>
                                {discordIdea.discord_group_id == 2 ?
                                    <img src="https://ve-portal.s3.ap-southeast-1.amazonaws.com/simmi+discord.jpg" class=" discord-lecture-image" alt="Simmi" /> :
                                    <img src="https://ve-portal.s3.ap-southeast-1.amazonaws.com/vernon+discord+(1).jpg" class="rounded-circle  discord-lecture-image" alt="Vernon"></img>
                                }
                            </Col>


                        </Row>
                    </Card>

                </Col>
            })}

            {subscribedDiscord.length <= 1 && filteredSimmiIdeaSubscribed.length == 0 && filteredSimmiIdeaUnSubscribe.length != 0 &&
                //handle simmi trade idea unsubscribing
                <>
                    <Col lg='6'>
                        <Card>
                            <Row className='align-items-center'>
                                <Col lg='7'>
                                    <CardBody>
                                        <CardTitle><h3>Simmi Trade Idea</h3></CardTitle>
                                        <CardSubtitle>Status: Unsubscribing</CardSubtitle>
                                        <CardText>Last day of access: {moment(filteredSimmiIdeaUnSubscribe[0].next_billing_date).format('yyyy-MM-DD')}</CardText>
                                        {/* <Button onClick={() => {
                                    navigate(`${process.env.PUBLIC_URL}/ve_discord/subscribe/simmi`)
                                }}>Subscribe</Button> */}
                                    </CardBody>
                                </Col>
                                <Col>
                                    <img src="https://ve-portal.s3.ap-southeast-1.amazonaws.com/simmi+discord.jpg" class="rounded-circle discord-lecture-image" alt="Simmi" />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </>

            }

            {subscribedDiscord.length <= 1 && filteredVernonIdeaSubscribed.length == 0 && filteredVernonIdeaUnSubscribe.length != 0 &&
                //handle vernon trade idea unsubscribing
                <>
                    <Col lg='6'>
                        <Card>
                            <Row className='align-items-center'>
                                <Col lg='7'>
                                    <CardBody>
                                        <CardTitle><h3>Vernon Trade Idea</h3></CardTitle>
                                        <CardSubtitle>Status: Unsubscribing</CardSubtitle>
                                        <CardText>You still able to see the trade idea until {moment(filteredSimmiIdeaUnSubscribe[0].next_billing_date).format('yyyy-MM-DD')}</CardText>
                                        {/* <Button onClick={() => {
                                    navigate(`${process.env.PUBLIC_URL}/ve_discord/subscribe/simmi`)
                                }}>Subscribe</Button> */}
                                    </CardBody>

                                </Col>
                                <Col>
                                    <img src="https://ve-portal.s3.ap-southeast-1.amazonaws.com/vernon+discord+(1).jpg" class="rounded-circle  discord-lecture-image" alt="Vernon" />
                                </Col>

                            </Row>
                        </Card>
                    </Col>
                </>

            }

            {/* Handle simmi confirm unsubscribe */}
            {
                subscribedDiscord.length <= 1 && filteredSimmiIdeaSubscribed.length == 0 && filteredSimmiIdeaUnSubscribe.length == 0 &&
                <>
                    <Col lg='6'>
                        <Card>
                            <CardBody>
                                <CardTitle><h3>Simmi Trade Idea</h3></CardTitle>
                                <CardSubtitle>Status: Inactive</CardSubtitle>
                                <CardText>Don't miss with Simmi trade idea</CardText>
                                <Button onClick={() => {
                                    navigate(`${process.env.PUBLIC_URL}/ve_discord/subscribe/simmi`)
                                }}>Subscribe</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </>
            }

            {/* Handle vernon confirm unsubscribe */}
            {
                subscribedDiscord.length <= 1 && filteredVernonIdeaSubscribed.length == 0 && filteredVernonIdeaUnSubscribe.length == 0 &&
                <>
                    <Col lg='6'>
                        <Card>
                            <CardBody>
                                <CardTitle><h3>Vernon Trade Idea</h3></CardTitle>
                                <CardSubtitle>Status: Inactive</CardSubtitle>
                                <CardText>Don't miss with Vernon trade idea</CardText>
                                <Button onClick={() => {
                                    navigate(`${process.env.PUBLIC_URL}/ve_discord/subscribe/vernon`)
                                }}>Subscribe</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </>
            }


        </Row >

    </>
}

export default VeDiscordSubscription


