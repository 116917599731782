import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Badge,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Spinner,
  Popover,
  PopoverHeader,
  PopoverBody
} from 'reactstrap'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import { ToastContainer, toast } from "react-toastify";
import NewsService from '../../network/services/news'

import Breadcrumb from '../../layout/breadcrumb'
import MtT5list from './MT5List'

import WalletService from '../../network/services/wallet'
import CheckInService from '../../network/services/checkin'
import CourseService from '../../network/services/courses'
import ReferralModal from './referral_modal'
import AnnouncementModal from './announcement_modal'
import Carousel from './carousel'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import "./dashboard.css"

import { useTranslation } from 'react-i18next'

import moment from 'moment'

import "intro.js/introjs.css";

import ReactGA from 'react-ga'

import SweetAlert from 'sweetalert2'

import PammMangerList from './PammManager/index'

// import courses from './courses.png'
// import mt5 from './mt5.png'
// import pamm from './pamm.png'

// import voucher from './voucher.png'
import './required.scss'



const Dashboard = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const verification = useSelector((state) => _.last(state.user.verifications))
  const showAnnouncement = useSelector((state) => state.announcement.showAnnouncement)
  const totalDeposit = useSelector((state) => state.wallet?.totalDeposit)
  const [checkInDay, setCheckInDay] = useState([])
  const [checkInMission, setCheckInMission] = useState(true)
  const [premiumCourse, setPremiumCourse] = useState([])
  const [isbusy, setIsbusy] = useState(false)




  const isVerifying = useSelector((state) => state.user.verifications)

  //divide as two arrays for mapping two row with space-between
  const shortcut = [
    // {
    //   navigation: '/wallet',
    //   image_link: wallet,
    //   name: t('dashboard.Wallet'),
    // },
    {
      navigation: '/tradeAccount',
      image_link: 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/trade.png',
      name: t('dashboard.TradeAccount'),
    },
    {
      navigation: '/monitoring',
      image_link: 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/monitoring.png',
      name: t('dashboard.Monitor'),
    },
    {
      navigation: '/courses',
      image_link: 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/courses.png',
      name: t('dashboard.Courses'),
    },
    {
      navigation: '/veFund',
      image_link: 'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/assets/pamm.png',
      name: 'VE Fund',
    },
    // {
    //   navigation: '/vouchers',
    //   image_link: voucher,
    //   name: t('dashboard.Vouchers'),
    // },
    // {
    //   navigation: '/referral_friend',
    //   image_link: refer,
    //   name: t('dashboard.Refer'),
    // },
    // {
    //   navigation: '/event',
    //   image_link: contest,
    //   name: t('dashboard.Contest'),
    // }
  ]

  const shortcut1 = [
    // {
    //   navigation: '/calendar',
    //   image_link: calendar,
    //   name: t('dashboard.Calendar'),
    // },

    // {
    //   navigation: '/vouchers',
    //   image_link: voucher,
    //   name: t('dashboard.Vouchers'),
    // },
    {
      navigation: '/ve_discord',
      image_link:  'https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/discordlogo.png',
      name: 'VE Discord',
    },

  ]

  const init = async () => {

    //format
    const firstDay = moment().startOf('week')
    const lastDay = moment().endOf('week')
    const formatFirstDay = firstDay.format('YYYY-MM-DD HH:mm:ss')
    const formatLastDay = lastDay.format('YYYY-MM-DD HH:mm:ss')

    setIsbusy(true)
    const coursesResult = await CourseService.getAll()
    setIsbusy(false)
    setPremiumCourse(coursesResult?.courses?.filter((course => !course.is_course_available && course.course_language == 'chinese')))

    const havenCheckIn = await CheckInService.get({
      startDate: formatFirstDay,
      endDate: formatLastDay
    })
    const result = await CheckInService.missionList()
    setCheckInMission(result?.mission_rewards_havent_redeemed)
    setCheckInDay(havenCheckIn?.checked_in_status)

    const walletResponse = await WalletService.get()
    dispatch({ type: 'SET_WALLETS', wallets: walletResponse.wallets })
    dispatch({
      type: 'SET_BALANCE',
      balance: walletResponse.wallet_balance
    })
    dispatch({
      type: 'SET_TOTAL_DEPOSIT',
      totalDeposit: walletResponse.total_deposit
    })
    dispatch({
      type: 'SET_TOTAL_WITHDRAWAL',
      totalWithdrawal: walletResponse.total_withdrawal
    })
    dispatch({
      type: 'SET_TOTAL_ACCOUNT_DEPOSIT',
      totalAccountDeposit: walletResponse.total_account_deposit
    })
    dispatch({
      type: 'SET_TOTAL_ACCOUNT_WITHDRAWAL',
      totalAccountWithdraw: walletResponse.total_account_withdraw
    })


  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: 'none' })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])


  // modal
  const [showReferral, setShowReferral] = useState(false)
  const toggleModal = () => {
    setShowReferral(!showReferral)
  }

  const toggleAnnouncementModal = () => {
    dispatch({ type: 'SET_ANNOUNCEMENTS_INIT', showAnnouncement: false })
  }

  const toChapterPage = (course, courseID) => {

    if (course?.is_course_available) {
      navigate(`${process.env.PUBLIC_URL}/courses/${course?.id}/chapters`)
    } else if (courseID === 9 || courseID === 10) {
      SweetAlert.fire({
        showClass: {
          popup: 'animate__animated animate__backInUp'
        },
        hideClass: {
          popup: 'animate__animated animate__backOutDown'
        },
        title: `${t('courses.ThisCourseIsBelongsTo')} ${course?.name}`,
        html: `<p>${t('courses.YouMay')} Find <a href="/support" target="_blank">Customer Service</a> To View This Content</p>`,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: t('financial.confirm'),
        reverseButtons: true,
      })
    }
    else {
      SweetAlert.fire({
        showClass: {
          popup: 'animate__animated animate__backInUp'
        },
        hideClass: {
          popup: 'animate__animated animate__backOutDown'
        },
        title: `${t('courses.ThisCourseIsBelongsTo')} ${course?.name}`,
        html: `<p>${t('courses.YouMay')} <a href="/tradeAccount" target="_blank">${t('courses.Upgrade')}</a> ${t('courses.ToThisPackageToViewThisContent')}</p>`,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: t('financial.confirm'),
        reverseButtons: true,
      })
    }
  }

  useEffect(() => {
    //make sure user have profile
    if (user?.profile.hasOwnProperty('id')) {
    } else {
      window.location.href = '/verify-details'
    }

  }, [])

  return (
    <>
      {/* Toastify if user able to apply starter package */}
      <Container>
        {(totalDeposit === 0 || (verification?.status !== 1 && verification?.status !== 0 || isVerifying === [])) &&

          <>



            {/* mobile used*/}




            {/* <Alert id='action-bar' style={{ top: '97px', margin: '0px', width: '100%', position: 'fixed', left: '0', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', zIndex: '2', backgroundColor: '#103c5a', border: 'none' }} color="dark" isOpen={visible} toggle={onDismiss}>
              <div id='div-action-bar' style={{ justifyContent: 'center' }} >
                <div className="m-r-20" style={{ color: ' #C4A569', fontWeight: '500' }}><img src={warn1} style={{ height: '20px', width: '20px', color: ' #C4A569', marginTop: '-3px', marginRight: '10px', fontWeight: 'Bold' }}></img>{t('dashboard.ActionNeedToTake')}</div>
                <div id='div-button-action-bar'>


                  {
                    verification?.status !== 1 && verification?.status !== 0 &&
                    <div className="m-r-10">
                      <Link to='/account#verification'>
                        <ActionBarButton id='button-to-KYC'>{t('dashboard.VerifyProfile')}</ActionBarButton>
                      </Link>
                    </div>
                  }

                  {
                    bank?.status !== 1 &&
                    <div className="m-r-20">
                      <Link to='/account#bank'>
                        <ActionBarButton id='button-to-KYC'>{t('dashboard.VerifyBankDetails')}</ActionBarButton>
                      </Link>
                    </div>
                  }
                </div>

              </div>

            </Alert> */}

          </>
        }

        <Row>
          {/*mobile used carousel*/}
          <Col lg='5' className='d-block d-lg-none' style={{ margin: '50px 0px 20px 0px' }}>
            <Carousel />
          </Col>
          <Col lg='7'>
            <MtT5list />

            <Container className='container-fluid' style={{ backgroundColor: '#F0F0F0', borderRadius: '10px', marginRight: '0px', marginBottom: '20px', opacity: '1', color: 'black' }}>

            </Container>

            <Container className='d-none d-lg-block d-xl-block' style={{ display: 'flex', flexWrap: 'wrap', margin: '0px', padding: '0px' }}>
              <Row style={{ justifyContent: 'space-between', width: '100%', margin: '0px', padding: '0px' }}>
                {shortcut?.map((shortcut) => (

                  <>

                    <Col lg='3'>
                      <div style={{ padding: '0px', margin: '0px', alignContent: 'center', boxShadow: '0px 4px 20px #0000000D', borderRadius: '20px' }}
                        onClick={() => {
                          navigate(`${process.env.PUBLIC_URL}${shortcut.navigation}`)
                        }}
                      >

                        <div className='text-center' style={{ display: 'block', height: '100px', width: '100%', borderRadius: '20px', backgroundColor: 'white', cursor: 'pointer', alignContent: 'center' }}>
                          <img src={shortcut.image_link} width='26px' height='26px' style={{ marginTop: '25px' }}></img>

                          <div className='text-center m-t-5' style={{ color: 'black' }}>
                            {shortcut.name}
                          </div>
                        </div>
                      </div>
                    </Col>

                  </>

                ))}
              </Row>

              <Row style={{ justifyContent: 'space-between', width: '100%', margin: '25px 0 0 0', padding: '0px' }}>
                {shortcut1?.map((shortcut1) => (

                  <>

                    <Col lg='12' style={{ padding: '0px', margin: '0px', alignContent: 'center', boxShadow: '0px 4px 20px #0000000D', borderRadius: '20px' }}
                      onClick={() => {
                        navigate(`${process.env.PUBLIC_URL}${shortcut1.navigation}`)
                      }}
                    >

                      <div className='text-center' style={{ display: 'block', height: '100px', width: '100%', borderRadius: '20px', backgroundColor: 'white', cursor: 'pointer', alignContent: 'center' }}>
                        <img src={shortcut1.image_link} width='26px' height='26px' style={{ marginTop: '25px' }}></img>
              
                        {shortcut1.name === 'Check In' || shortcut1.name === '签到' ?
                          (checkInMission === true || checkInDay === 0 ?

                            <div style={{ height: '10px', width: '10px', backgroundColor: 'red', borderRadius: '50%', display: 'inline-block', position: 'absolute', top: '20%' }} />
                            : '')
                          : ''

                        }
                        <div className='text-center m-t-5' style={{ color: 'black' }}>
                          {shortcut1.name}
                        </div>
                      </div>
                    </Col>

                  </>

                ))}



              </Row>
              <Container className='d-none d-xl-block d-lg-block d-md-block'>

                {isbusy &&
                  <div className='mt-5' style={{ textAlign: 'center' }}>
                    <Row style={{ marginTop: "25px", overflow: 'auto', display: 'flex', flexWrap: 'nowrap', zIndex: '1' }}>
                      <Col xl='4' lg='6' xs='8' sm='8' className="result" style={{ marginBottom: '30px' }}>
                        <Card
                          style={{
                            boxShadow: '0px 4px 20px #0000000D',
                            backgroundColor: 'white',
                            color: 'black',

                          }}
                        >
                          <div style={{ marginTop: '-5px' }}>
                            <Skeleton width="100%" height={130} borderRadius={15} />
                          </div>
                          <Row className="ml-3 mt-3" style={{ color: 'black' }}>
                            <Skeleton width={150} height={10} />
                          </Row>

                          <Row className="ml-3" style={{ color: 'black' }}>
                            <Skeleton width={100} height={10} />
                          </Row>
                          <Row style={{ color: 'black', marginBottom: '0px' }}>
                            <Skeleton />
                          </Row>
                        </Card>
                      </Col>
                      <Col xl='4' lg='6' xs='8' sm='8' className="result" style={{ marginBottom: '30px' }}>
                        <Card
                          style={{
                            boxShadow: '0px 4px 20px #0000000D',
                            backgroundColor: 'white',
                            color: 'black',

                          }}
                        >
                          <div style={{ marginTop: '-5px' }}>
                            <Skeleton width="100%" height={130} borderRadius={15} />
                          </div>
                          <Row className="ml-3 mt-3" style={{ color: 'black' }}>
                            <Skeleton width={150} height={10} />
                          </Row>

                          <Row className="ml-3" style={{ color: 'black' }}>
                            <Skeleton width={100} height={10} />
                          </Row>
                          <Row style={{ color: 'black', marginBottom: '0px' }}>
                            <Skeleton />
                          </Row>
                        </Card>
                      </Col>
                      <Col xl='4' lg='6' xs='8' sm='8' className="result" style={{ marginBottom: '30px' }}>
                        <Card
                          style={{
                            boxShadow: '0px 4px 20px #0000000D',
                            backgroundColor: 'white',
                            color: 'black',

                          }}
                        >
                          <div style={{ marginTop: '-5px' }}>
                            <Skeleton width="100%" height={130} borderRadius={15} />
                          </div>
                          <Row className="ml-3 mt-3" style={{ color: 'black' }}>
                            <Skeleton width={150} height={10} />
                          </Row>

                          <Row className="ml-3" style={{ color: 'black' }}>
                            <Skeleton width={100} height={10} />
                          </Row>
                          <Row style={{ color: 'black', marginBottom: '0px' }}>
                            <Skeleton />
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                }

                {premiumCourse?.length > 0 ?
                  <>
                    {premiumCourse.length > 0 &&
                      <h5 className='mt-3'>{t("courses.Trending")}</h5>
                    }

                    <Row className="courseScrollBar" style={{ marginTop: "30px", marginBottom: '25px', overflow: 'auto', display: 'flex', flexWrap: 'nowrap', zIndex: '1' }}>

                      {premiumCourse?.map(course => {
                        return (
                          course?.status &&
                          <Col xl='4' lg='6' xs='8' sm='8' className="result mb-5">

                            <Card
                              style={{
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                backgroundColor: 'white',
                                color: 'black',
                                cursor: 'pointer'

                              }}
                              onClick={() => {
                                toChapterPage(course)
                                ReactGA.event({
                                  category: 'Courses',
                                  action: 'Click not available courses ',
                                  label: 'Click not available courses '
                                })
                              }}
                            >

                              <div style={{

                                filter: course?.is_course_available === false ? 'contrast(0.5)' : ''
                              }}>

                                <div >
                                  {
                                    course?.is_course_available === false &&
                                    <Badge style={{ position: 'absolute', left: '20px', top: '15px' }} color="danger">{t('courses.NotAvailable')}</Badge>

                                  }
                                  <img alt='course background' src={course?.cover_image_url}
                                    style={{
                                      width: '100%',
                                      maxHeight: '120px',
                                      borderTopLeftRadius: '15px',
                                      borderTopRightRadius: '15px'
                                    }}
                                  />

                                </div>

                                <Row style={{ marginTop: '-17px', color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>

                                  <Badge
                                    className='p-t-10'
                                    style={{
                                      borderRadius: '7px',
                                      color: 'black',
                                      width: 'auto',
                                      height: 'auto',
                                      fontSize: '12px'
                                    }}
                                    color="primary"
                                  >
                                    {course?.difficulty}
                                  </Badge>
                                  <Badge
                                    className='p-t-10'
                                    style={{
                                      textTransform: 'capitalize',
                                      borderRadius: '7px',
                                      color: 'black',
                                      width: 'auto',
                                      height: 'auto',
                                      backgroundColor: '#CDCFD7',
                                      fontSize: '12px'
                                    }}
                                    color=""
                                  >

                                    {course?.course_language}
                                  </Badge>


                                </Row>

                                <Row style={{ color: 'black', marginBottom: '0px', marginTop: '10px', alignItems: 'center', justifyContent: 'center' }}>

                                  <h4>{course?.name}</h4>

                                </Row>

                                <Row className="mt-0" style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>
                                  <div>
                                    <p style={{ marginBottom: '0', marginTop: '6px' }}>{course?.author}</p>
                                  </div>
                                </Row>

                                <Row className="mt-2" style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>

                                  <div style={{ display: 'flex' }}>
                                    <h5 style={{ margin: '0', textAlign: 'right', color: ' #C4A569' }}>{course?.course_progress}% </h5>
                                    <p className='ml-2'>{t('courses.Complete')}</p>
                                  </div>

                                </Row>
                                <Row style={{ color: 'black', marginBottom: '0px', alignItems: 'center', justifyContent: 'center' }}>
                                  <Button
                                    style={{
                                      borderRadius: '26px',
                                      width: '150px',
                                      height: '50px',
                                      boxShadow: '0px 7px 20px #00000014',
                                      color: 'black',
                                      fontSize: '14px',
                                      fontWeight: '500',
                                      marginBottom: '-30px',

                                    }}
                                    color="primary"
                                  >
                                    {t('dashboard.LearnMore')}

                                  </Button>

                                </Row>

                              </div>


                            </Card>


                          </Col>
                        )

                      })}
                    </Row>
                  </> : " "
                }
              </Container>


            </Container>

            {/* mobile size */}
            <Container className='d-block d-sm-block d-xs-block d-md-block d-lg-none d-xl-none mobile-function-bar' style={{ display: 'flex', flexWrap: 'wrap', padding: '0px' }}>
              <Row style={{ justifyContent: 'space-between', width: '100%', height: '80px', margin: '0px 0px 10px 0px', padding: '0px' }}>
                {shortcut?.map((shortcut) => (

                  <>

                    <Col style={{ padding: '0px', margin: '5px', alignContent: 'center', boxShadow: '0px 4px 20px #0000000D', borderRadius: '20px' }}
                      onClick={() => {
                        navigate(`${process.env.PUBLIC_URL}${shortcut.navigation}`)
                      }}
                    >

                      <div className='text-center' style={{ display: 'block', height: '80px', width: '100%', borderRadius: '10px', backgroundColor: 'white', cursor: 'pointer', alignContent: 'center' }}>
                        <img src={shortcut.image_link} width='24px' height='24px' style={{ marginTop: '20px' }}></img>

                        <div className='text-center m-t-5' style={{ fontSize: '8px', color: 'black' }}>
                          {shortcut.name}
                        </div>
                      </div>
                    </Col>

                  </>

                ))}
              </Row>

              <Row style={{ justifyContent: 'space-between', width: '100%', height: '80px', margin: '10px 0px 0px 0px', padding: '0px' }}>
                {shortcut1?.map((shortcut1) => (

                  <>

                    <Col style={{ padding: '0px', margin: '5px', alignContent: 'center', boxShadow: '0px 4px 20px #0000000D', borderRadius: '20px' }}
                      onClick={() => {
                        navigate(`${process.env.PUBLIC_URL}${shortcut1.navigation}`)
                      }}
                    >

                      <div className='text-center' style={{ display: 'block', height: '80px', width: '100%', borderRadius: '10px', backgroundColor: 'white', cursor: 'pointer', alignContent: 'center' }}>

                        {shortcut1.name === 'Check In' || shortcut1.name === '签到' ?
                          <img src={shortcut1.image_link} width='24px' height='24px' style={{ marginTop: '20px' }}></img>
                          :
                          <img src={shortcut1.image_link} width='22px' height='22px' style={{ marginTop: '20px' }}></img>

                        }

                        {shortcut1.name === 'Check In' || shortcut1.name === '签到' ?
                          (checkInMission === true || checkInDay === 0 ?

                            <div style={{ height: '10px', width: '10px', backgroundColor: 'red', borderRadius: '50%', display: 'inline-block', position: 'absolute', top: '20%' }} />
                            : '')
                          : ''

                        }
                        <div className='text-center m-t-5' style={{ fontSize: '8px', color: 'black' }}>
                          {shortcut1.name}
                        </div>
                      </div>
                    </Col>

                  </>

                ))}



              </Row>
              {/* {partners.length > 0 && (
                <Row style={{ justifyContent: 'space-between', width: '100%', height: '80px', margin: '10px 0px 0px 0px', padding: '0px' }}>
                  {partners.length > 0 ? (
                    <>
                      <Col style={{ padding: '0px', margin: '5px', alignContent: 'center', boxShadow: '0px 4px 20px #0000000D', borderRadius: '20px' }}
                        onClick={() => {
                          navigate(`${process.env.PUBLIC_URL}/partner`)
                        }}
                      >
                        <div className='text-center' style={{ display: 'block', height: '80px', width: '100%', borderRadius: '20px', backgroundColor: 'white', cursor: 'pointer' }}>
                          <img src={partner} alt="partner" width='24px' height='24px' style={{ marginTop: '20px' }}></img>

                          <div className='text-center m-t-5' style={{ color: 'black' }}>
                            IB
                          </div>
                        </div>
                      </Col>
                      <Col style={{ backgroundColor: 'transparent', padding: '0px', margin: '5px', height: '80px', width: '100%', boxShadow: 'none' }}>
                      </Col>
                      <Col style={{ backgroundColor: 'transparent', padding: '0px', margin: '5px', height: '80px', width: '100%', boxShadow: 'none' }}>
                      </Col>
                      <Col style={{ backgroundColor: 'transparent', padding: '0px', margin: '5px', height: '80px', width: '100%', boxShadow: 'none' }}>
                      </Col>
                      <Col style={{ backgroundColor: 'transparent', padding: '0px', margin: '5px', height: '80px', width: '100%', boxShadow: 'none' }}>
                      </Col>

                    </>

                  ) : (
                      <Col style={{ backgroundColor: 'transparent', padding: '0px', margin: '5px', height: '80px', width: '100%', boxShadow: 'none' }}>
                      </Col>
                    )}

                </Row>
              )} */}
            </Container>




          </Col>



          <Col lg='5'>
            <Col className='d-none d-lg-block d-xl-block d-xxl-block'>
              <Carousel />
            </Col>

            {/* <Card style={{
              padding: '15px 15px',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
              borderRadius: '5px',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              border: 'none'
            }}>
              <Row style={{ padding: '0px 15px', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'transparent' }}>
                <h5 style={{ fontWeight: 'normal' }}>VE Fund Manager {t('pamm.statistics')}</h5>
                <p
                  style={{ marginBottom: '0', cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/veFund`)
                  }}
                >
                  {t('dashboard.ViewMore')}
                </p>
              </Row>
              <Row>
                <PammMangerList />
              </Row>

            </Card> */}
          </Col>





        </Row>

      </Container>

      {
        showReferral && (
          <Modal isOpen={showReferral} backdrop={true} centered={true}>
            <ModalHeader toggle={toggleModal}>{t('dashboard.inviteFriendsAndEarnMoney')}</ModalHeader>
            <ModalBody>
              <ReferralModal />
            </ModalBody>
          </Modal>
        )
      }

      {/* {
        <Modal isOpen={showAnnouncement} backdrop={true} centered={true}>
          <ModalHeader
            style={{
              backgroundColor: 'rgb(15, 60, 90)',
              color: 'white',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {t('dashboard.importantAnnouncement')}
          </ModalHeader>
          <ModalBody>
            <AnnouncementModal />
            <Button
              style={{ display: 'block', margin: '0 auto' }}
              color="primary"
              onClick={toggleAnnouncementModal}
            >
              {t('dashboard.noted')}
            </Button>
          </ModalBody>
        </Modal>
      } */}



    </>
  )
}

export default Dashboard
