import React, { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input, CardHeader, Container } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import Breadcrumb from '../../../layout/breadcrumb'
import {
    useParams,
} from "react-router-dom";

import StripeService from '../../../network/services/stripe'
import UserService from '../../../network/services/user'

import { HelpCircle } from 'react-feather'
import SweetAlert from 'sweetalert2'



const VeDiscordSubscribeForm = () => {

    const { lectureName } = useParams()
    const [discordGroups, setDiscordGroups] = useState([])

    const [simmiSubscription, setSimmiSubScription] = useState([])
    const [vernonSubscription, setVernonSubScription] = useState([])
    const [isMember, setIsMember] = useState(false)

    const { t } = useTranslation()
    const { register, handleSubmit, errors, formState } = useForm()
    const { isSubmitting } = formState


    const fetchDiscordSubscription = async () => {

        try {
            const result = await StripeService.checkDiscordSubscription('')

            const getDiscordGroupResponse = await StripeService.getDiscordGroups()


            const resultMembers = await UserService.getMyselfMembers()

            setIsMember(resultMembers.members)

            const subscribedResult = result.result.filter((result) => {

                return result.status == 1 || result.status == '3'
            })

            const filteredSimmiIdeaSubscribed = subscribedResult.filter(idea => {
                return idea.discord_group_id == 2
            })

            const filteredVernonIdeaSubscribed = subscribedResult.filter(idea => {
                return idea.discord_group_id == 1
            })

            setDiscordGroups(getDiscordGroupResponse.result)
            setSimmiSubScription(filteredSimmiIdeaSubscribed)
            setVernonSubScription(filteredVernonIdeaSubscribed)


        } catch (error) {

            console.log(error)
        }
    }

    useEffect(() => {
        fetchDiscordSubscription()
    }, [])


    const submitUpdate = async (data) => {


        try {
            //check user exist in discord
            const userExistsResult = await StripeService.checkUserExistsDiscord(data)


            if (userExistsResult.is_user_exists == false) {

                SweetAlert.fire({
                    title: 'Please make sure the username has already joined VE Discord Server ',
                    icon: 'error'
                })
                return
            } else {
                //if username exist in discord, proceed to payment
                const result = await StripeService.checkoutStripe(data)
                console.log(result)
                window.location.href = result.redirect_url
            }


        }
        catch (error) {

            const errorCode = error.code
            const errorTranslate = error.translate_params
            SweetAlert.fire({
                title: t(errorCode, errorTranslate),
                icon: 'error'
            })
        }



    }

    const checkIsSubscribe = (discordGroup) => {
        if (discordGroup.name == 'Simmi Trade Idea' && simmiSubscription.length > 0) {
            return true
        } else if (discordGroup.name == 'Vernon Trade Idea' && vernonSubscription.length > 0) {
            return true
        } else {
            return false
        }
    }

    return <>
        <Row>
            <Breadcrumb parent="" title=""></Breadcrumb>
            <Col lg='6'>
                <div className='mt-3 mb-3 rounded' >
                    <img className='img-fluid rounded' src='https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/discord_description.webp' alt="discord benefit" />
                </div>
            </Col>
            <Col lg='6'>
                <Form
                    className="theme-form"
                    onSubmit={handleSubmit(submitUpdate)}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: '0.75rem'
                    }}
                >
                    <Card>
                        <CardHeader>
                            <h5>Subscription Plans</h5>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Label className="col-form-label pt-0">
                                    Discord Username
                                    <span style={{ color: 'red' }}>*</span>
                                    <HelpCircle onClick={() => {
                                        window.open("https://th-kyc-backup.s3.ap-southeast-1.amazonaws.com/discord-step-3_1.webp", "_blank")
                                    }} style={{ width: '18px', color: '#C4A569', marginLeft: '3px', cursor: 'pointer' }} />
                                </Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name="discord_username"
                                    innerRef={register({ required: true })}

                                >

                                </Input>
                                <span>**Please make sure this username's already joined <a href='https://discord.com/invite/QDdvJZsQQD' target='_blank' alt='discord server'> VE Discord</a></span>

                                <span style={{ color: 'red' }}>{errors.title && t("user.TitleIsRequired")}</span>
                            </FormGroup>

                            <FormGroup>
                                <Label className="col-form-label pt-0 mt-3">
                                    Select Your Plan
                                    <span style={{ color: 'red' }}>*</span>
                                </Label>
                                <div>
                                    {isMember ? discordGroups.map((discordGroup) => {
                                        return <div>
                                            <Input
                                                type="radio"
                                                innerRef={register({ required: true })}
                                                disabled={checkIsSubscribe(discordGroup)}
                                                value={discordGroup.id}
                                                id={discordGroup.name}
                                                name='discord_group_id'
                                                className='ml-0'
                                            />
                                            <Row className='ml-4 justify-content-between'>
                                                <label htmlFor={discordGroup.name}>{discordGroup.name}</label>
                                                {discordGroup.name == 'Simmi Trade Idea' &&
                                                    <label htmlFor={discordGroup.name}><span style={{ textDecoration: 'line-through' }}>${discordGroup.non_member_price}</span> ${discordGroup.member_price} every month</label>
                                                }
                                                {discordGroup.name == 'Vernon Trade Idea' &&
                                                    <label htmlFor={discordGroup.name}> ${discordGroup.member_price} every month</label>
                                                }

                                            </Row>
                                        </div>
                                    })
                                        : discordGroups.map((discordGroup) => {
                                            return <div>
                                                <Input
                                                    type="radio"
                                                    innerRef={register({ required: true })}
                                                    disabled={checkIsSubscribe(discordGroup)}
                                                    value={discordGroup.id}
                                                    id={discordGroup.name}
                                                    name='discord_group_id'
                                                    className='ml-0'
                                                />
                                                <Row className='ml-4 justify-content-between'>
                                                    <label htmlFor={discordGroup.name}>{discordGroup.name}</label>
                                                    {discordGroup.name == 'Simmi Trade Idea' &&
                                                        <label htmlFor={discordGroup.name}>${discordGroup.non_member_price} every month</label>
                                                    }
                                                    {discordGroup.name == 'Vernon Trade Idea' &&
                                                        <label htmlFor={discordGroup.name}> ${discordGroup.member_price} every month</label>
                                                    }

                                                </Row>
                                            </div>
                                        })
                                    }

                                </div>

                            </FormGroup>

                            <FormGroup>
                                <div className="custom-control custom-checkbox mb-3 mt-5">
                                    <Input className="custom-control-input" id='discord_agreement' type="checkbox" required />
                                    <Label className="custom-control-label" htmlFor="discord_agreement">
                                        By submitting your subscription, you allow Value Envision Sdn Bhd to charge your card for this payment and future payments in accordance with their terms. You can always cancel your subscription.
                                    </Label>
                                    <div className="invalid-feedback">{t("user.PleaseAgreeToTerms")}</div>
                                </div>
                            </FormGroup>

                            <Button
                                color="primary btn-block"
                                type="submit"
                                style={{ maxWidth: 150, float: 'right', marginBottom: '40px' }}
                            >
                                {isSubmitting ? t("user.Loading") : t("user.Submit")}
                            </Button>

                        </CardBody>
                    </Card>






                </Form>
            </Col>

        </Row>

    </>
}

export default VeDiscordSubscribeForm


