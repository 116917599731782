import React, { Fragment, useEffect, useState } from 'react'
import { Container, Button, Card, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, FormText } from 'reactstrap'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import LiveAccounts from './live'
import AccountService from "../../network/services/account"
import FilesService from '../../network/services/files'
import CourseService from '../../network/services/courses'
import { useForm, Controller } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import SweetAlert from 'sweetalert2'

// import ContestAcocunts from './competition_account'
import Breadcrumb from '../../layout/breadcrumb'
import './index.css'

const MT5account = () => {
    const { t } = useTranslation()
    const [nav_Check, setNav_Check] = useState('Live')
    const [showModal, setShowModal] = useState(false)
    const [selectedCourses, setSelectedCourses] = useState('PPT');
    const [selectedAcc, setSelectedAcc] = useState('New')
    const user = useSelector((state) => state.user)

    let history = useHistory()
    const dispatch = useDispatch()
    const verification = useSelector((state) => state.verification)

    const { control, register, handleSubmit, errors, reset, watch, clearErrors, setError } = useForm()

    const selectedAccount = useSelector((state) => state.monitoring.account)

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [noNeedSync, setNoNeedSync] = useState(true)
    const toggle = () => setDropdownOpen((prevState) => !prevState)
    const [isLoading, setIsLoading] = useState(false)
    const [enrollModal, setEnrollModal] = useState(false)
    const [isbusy, setBusy] = useState(false)
    const [filterCourses, setFilterCourses] = useState('23')
    const [uploadError, setUploadError] = useState('')
    const [filterCoursesPdf, setFilterCoursesPdf] = useState([])
    const [checkBox, setCheckBox] = useState(false)
    const [checkTnc, setCheckTnC] = useState(false)

    const checkTncTick = () => {
        if (checkTnc == false) {
            setCheckBox(true)

        } else if (checkTnc == true) {
            setCheckBox(false)

        }
    }

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const init = async () => {
        // live accounts
        setIsLoading(true)
        try {
            const result = await AccountService.getLiveAndIbAccount()
            setAccounts(result.accounts.filter(acc => acc.balance === 0 && acc.credit === 0))
            setNoNeedSync(result.success)
            setIsLoading(false)
        }
        catch (error) {
            setIsLoading(false)
        }
    }

    const checkActive = (e) => {
        if (e == 'Enrollment') {
            setNav_Check('Enrollment')
            setEnrollModal(true)
            setShowModal(true)
        } else if (e == 'Live') {
            setNav_Check('Live')
            setEnrollModal(false)
        }
    }

    useEffect(() => {
        init()
        checkID()
    }, [selectedCourses])

    const checkID = async () => {
        const checkCourseID = await CourseService.getAll()
        if (selectedCourses != '') {
            const filterCourseID = checkCourseID?.courses.filter(courses => courses.name == selectedCourses)
            setFilterCourses(filterCourseID[0].id ?? '')
            setFilterCoursesPdf(filterCourseID)
        }
    }

    const submitChallenge = async (data) => {
        checkTncTick()
        if (verification.uploadRECEIPT?.file == null) {
            setUploadError('Attachment is empty. Please upload your receipt')
            dispatch({ type: 'SET_UPLOAD_RECEIPT', uploadRECEIPT: { error: 'Please select one' } })
            return
        }
        setUploadError('')

        try {

            const attachment = await FilesService.create(verification.uploadRECEIPT?.file)
            if (checkTnc == false) {
                setCheckBox(true)
                return
            }
            setBusy(true)
            // const result = await ChallengeService.purchaseChallenge({
            //     challenge_id: selectedChallenge,
            //     payment_method_id: selectedBlockChainId,
            //     attachment: attachment.data,
            //     payment_amount: selectedPrice + withdrawfee?.withdraw_fee + (selectedPrice * withdrawfee?.charge_percentage / 100)
            // })

            const result = await CourseService.enrollCourse({
                name: data.name,
                identification_number: data.identity_number,
                mailing_address: data.mailing_address,
                payment_receipt: attachment.data,
                account_id: selectedAccount,
                course_id: filterCourses
            })
            setBusy(false)

            if (result) {
                SweetAlert.fire({
                    showClass: {
                        popup: 'animate__animated animate__backInUp'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__backOutDown'
                    },
                    title: 'Success',
                    text: 'You have succesfully submitted your payment receipt, we will proceed the request around 2-3 working days, once your request is proceed you will send the result to your email. Thank you !',
                    icon: 'success',
                }).then(() => {
                    window.location.href = '/tradeAccount'
                    console.log(result)
                })
            }


        } catch (error) {
            // console.log(error)
            // SweetAlert.fire({
            //     title: error.message,
            //     icon: 'error'
            // })

            const errorCode = error.message
            const errorTranslate = error.translate_params
            SweetAlert.fire({
                title: t(errorCode, errorTranslate),
                icon: 'error'
            })

        }
    }
    return (
        <Container>
            <Breadcrumb parent={t('dashboard.TradeAccount')}></Breadcrumb>

            <div className="d-none d-lg-block d-xl-block" style={{ display: 'flex', flexWrap: 'nowrap', marginBottom: '15px', justifyContent: 'flex-end' }}>
                <Row style={{ justifyContent: 'flex-end', marginTop: '30px', marginRight: '20px' }}>
                    {user.user.is_th_sync === true ?
                        <div style={{ marginRight: '10px' }}>
                            <Button
                                style={{
                                    color: 'black',
                                    width: '200px',
                                    height: '57px',
                                    borderRadius: '20px',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    boxShadow: '0px 7px 20px #00000014',
                                    backgroundColor: 'white',
                                    border: '1px solid #C4A569'

                                }}
                                color=""
                                onClick={(e) => { checkActive('Enrollment') }}
                            >
                                {t('live.Enrollment')}
                            </Button>
                        </div>
                        : ''
                    }
                    <div style={{ marginRight: '10px' }}>
                        <Button
                            style={{
                                color: 'black',
                                width: '200px',
                                height: '57px',
                                borderRadius: '20px',
                                fontSize: '16px',
                                fontWeight: '500',
                                boxShadow: '0px 7px 20px #00000014',
                                backgroundColor: 'white'

                            }}
                            color="primary"
                            onClick={(e) => { checkActive('Live') }}
                        >
                            {t('live.liveAccounts')}
                        </Button>
                    </div>

                    {/* <div style={{ marginRight: '10px' }}>
                        <Button
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                width: '200px',
                                height: '57px',
                                borderRadius: '20px',
                                fontSize: '20px',
                                fontWeight: '500',
                                boxShadow: '0px 7px 20px #00000014'

                            }}
                            color={nav_Check === 'Demo' ? 'primary' : 'white'}
                            onClick={(e) => { setNav_Check(e.target.innerHTML) }}>
                            Demo
                        </Button>
                    </div> */}

                    {/* <div>
                        <Button
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                width: '200px',
                                height: '57px',
                                borderRadius: '20px',
                                fontSize: '20px',
                                fontWeight: '500',
                                boxShadow: '0px 7px 20px #00000014'

                            }}
                            color={nav_Check === 'Contest' ? 'primary' : 'white'}
                            onClick={(e) => { setNav_Check(e.target.innerHTML) }}>
                            Contest
                        </Button>
                    </div> */}

                </Row>
            </div>

            <div className='m-b-15 d-lg-none d-xl-none' style={{ display: 'flex', marginBottom: '15px', marginTop: '0px', justifyContent: 'center' }}>
                {user.user.is_th_sync === true ?
                    <div style={{ marginRight: '10px' }}>
                        <Button
                            style={{
                                color: 'black',
                                width: 'auto',
                                height: '60px',
                                borderRadius: '15px',
                                fontSize: '16px',
                                fontWeight: '500',
                                boxShadow: '0px 7px 20px #00000014',
                                backgroundColor: 'white',
                                border: '1px solid #C4A569'

                            }}
                            color=""
                            onClick={(e) => { checkActive('Enrollment') }}
                        >
                            {t('live.Enrollment')}
                        </Button>
                    </div>
                    : ''}
                <div style={{ marginRight: '10px' }}>
                    <Button
                        style={{
                            color: 'black',
                            width: 'auto',
                            height: '60px',
                            borderRadius: '15px',
                            fontSize: '16px',
                            fontWeight: '500',
                            boxShadow: '0px 7px 20px #00000014',
                            backgroundColor: 'white'

                        }}
                        color="primary"
                        onClick={(e) => { checkActive('Live') }}
                    >
                        {t('live.liveAccounts')}
                    </Button>
                </div>

                {/* <div style={{ marginRight: '10px' }}>
                        <Button
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                width: '100px',
                                height: '50px',
                                borderRadius: '20px',
                                fontSize: '16px',
                                fontWeight: '500',
                                boxShadow: '0px 7px 20px #00000014'

                            }}
                            color={nav_Check === 'Demo' ? 'primary' : 'white'}
                            onClick={(e) => { setNav_Check(e.target.innerHTML) }}>
                            Demo
                        </Button>
                    </div> */}
                {/* <div>
                        <Button
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                width: '100%',
                                height: '50px',
                                borderRadius: '20px',
                                fontSize: '16px',
                                fontWeight: '500',
                                boxShadow: '0px 7px 20px #00000014'

                            }}
                            color={nav_Check === 'Contest' ? 'primary' : 'white'}
                            onClick={(e) => { setNav_Check(e.target.innerHTML) }}>
                            Contest
                        </Button>
                    </div> */}

            </div>

            <Container className='mobile-css'>

                <LiveAccounts />

                {enrollModal === true ?
                    <Modal isOpen={showModal} backdrop={true} centered={true}>
                        <ModalHeader toggle={toggleModal}>
                            <h5>Enroll PPT / SWEA</h5>
                        </ModalHeader>
                        <ModalBody style={{ textAlign: 'left' }}>
                            <Label className='mt-3'>Name</Label>
                            <FormGroup>
                                <Input
                                    name="name"
                                    type="text"
                                    defaultValue=""
                                    innerRef={register({ required: 'Name is Required' })}
                                    placeholder="**Name"
                                    style={{ backgroundColor: '#F2F2F2', borderRadius: '8px' }}

                                >

                                </Input>
                                <span style={{ color: 'red' }}>
                                    {errors.name && errors.name.message}
                                </span>
                            </FormGroup>

                            <Label>Identidy Card (IC) / Passport Number</Label>
                            <FormGroup>
                                <Input
                                    name="identity_number"
                                    type="text"
                                    defaultValue=""
                                    innerRef={register({ required: 'Identity number is Required' })}
                                    placeholder="**Identity Number / Passport Number"
                                    style={{ backgroundColor: '#F2F2F2', borderRadius: '8px' }}

                                >

                                </Input>
                                <span style={{ color: 'red' }}>
                                    {errors.identity_number && errors.identity_number.message}
                                </span>
                            </FormGroup>

                            <Label>Course (PPT / SWEA)</Label>
                            <FormGroup>
                                <Row className='m-0 p-0'>
                                    <Col className='pl-4'>
                                        <div>
                                            <Input type="radio" checked={selectedCourses == 'PPT'} onChange={() => setSelectedCourses('PPT')}>
                                                <span class="checkmark"></span>
                                            </Input>
                                        </div>
                                        <div>
                                            PPT

                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <Input type="radio" checked={selectedCourses == 'SWEA'} onChange={() => setSelectedCourses('SWEA')}>
                                                <span class="checkmark"></span>
                                            </Input>
                                        </div>
                                        <div>
                                            SWEA

                                        </div>

                                    </Col>
                                </Row>
                            </FormGroup>
                            <Label>$100 Credit Benefit Account</Label>
                            <FormGroup>
                                <Row className='m-0 p-0'>
                                    <Col className='pl-4'>
                                        <div>
                                            <Input type="radio" checked={selectedAcc == 'New'} onChange={(e) => { setSelectedAcc('New') }}>
                                                <span class="checkmark"></span>
                                            </Input>
                                        </div>
                                        <div>
                                            New Account

                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <Input type="radio" checked={selectedAcc == 'Prev'} onChange={(e) => { setSelectedAcc('Prev') }}>
                                                <span class="checkmark"></span>
                                            </Input>
                                        </div>
                                        <div>
                                            Existing Account

                                        </div>

                                    </Col>
                                </Row>
                                <Row className='ml-0 mt-3'>
                                    {selectedAcc == 'Prev' && accounts?.length > 0 ?
                                        <>
                                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                                <DropdownToggle caret color="" style={{ minWidth: 150, backgroundColor: 'white', border: '1px solid black', color: 'black', fontSize: '18px', fontWeight: '500' }}>
                                                    #{selectedAccount != null ? selectedAccount.account_login :
                                                        accounts[0]?.account_login}
                                                </DropdownToggle>
                                                <DropdownMenu style={{ boxShadow: '0px 7px 20px #00000014' }}>

                                                    {accounts?.length > 0 &&
                                                        accounts.map((account, index) => {
                                                            return (
                                                                <Fragment key={account.id}>
                                                                    <DropdownItem
                                                                        style={{ borderRadius: '20px' }}
                                                                        onClick={() => {
                                                                            dispatch({ type: "SET_MONITORING_ACCOUNT", account: account })
                                                                        }}
                                                                    >
                                                                        #{account.account_login}
                                                                    </DropdownItem>
                                                                    {index !== accounts.length - 1 && <DropdownItem divider />}
                                                                </Fragment>
                                                            )
                                                        })}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </>
                                        : selectedAcc == 'Prev' && accounts?.length === 0 ?
                                            <p style={{ color: 'red' }}>
                                                You do not have any existing account fulfill Terms and Condition
                                            </p>
                                            : ''
                                    }
                                </Row>
                            </FormGroup>
                            <FormGroup>

                                <Label>Transfer Receipt</Label>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Input
                                            type="file"
                                            name="file"
                                            accept="image/png, image/jpeg, application/pdf"
                                            onChange={(e) => {
                                                dispatch({
                                                    type: 'SET_UPLOAD_RECEIPT',
                                                    uploadRECEIPT: { file: e?.currentTarget?.files[0] }
                                                })
                                            }}
                                        />
                                        <FormText color="muted">.jpg, .png, pdf, max 10 MB</FormText>
                                        {/* <span style={{ color: 'red' }}>{idFront?.error != null && idFront.error}</span> */}
                                        <p style={{ color: 'red', paddingBottom: '0px' }}>{uploadError}</p>

                                    </Col>
                                </Row>
                            </FormGroup>
                            <Label>Mailing Address</Label>
                            <FormGroup>
                                <Input
                                    name="mailing_address"
                                    type="text"
                                    defaultValue=""
                                    innerRef={register({ required: 'Mailing address is required' })}
                                    placeholder="** No X, Jalan XXXX"
                                    style={{ backgroundColor: '#F2F2F2', borderRadius: '8px' }}

                                >

                                </Input>
                                <span style={{ color: 'red' }}>
                                    {errors.mailing_address && errors.mailing_address.message}
                                </span>
                            </FormGroup>
                            <Row className="m-0">

                                <a href={filterCoursesPdf[0].enrollment_form_en} target="_blank">
                                    <p className='pdf-text mb-0'>Terms and Condition</p>
                                </a>
                                <p>
                                    The purpose of this action is to retrieve your personal information, which will be used to complete Service and Condition Form. The Form will be sent to your email upon completion.
                                </p>
                            </Row>
                            <Row className="ml-0 d-flex" style={{flexDirection:'column'}}>

                                <Row className="ml-0">
                                    <input type="checkbox" className='ml-1 mr-1' onClick={() => setCheckTnC(!checkTnc)} />
                                    I have read all agreements.
                                </Row>


                                {checkBox === true ?
                                    <Row className="ml-0">
                                        <p style={{ color: 'red', marginBottom: '0px' }}>Required</p>

                                    </Row>
                                    :
                                    ''}



                            </Row>
                            <Button
                                color='primary'
                                className="mt-3 pull-right"
                                onClick={() => handleSubmit(submitChallenge)()}
                                disabled={isbusy == true}
                            >
                                Submit
                            </Button>


                            {/* <div>
                                <Button
                                    style={{ marginTop: '15px' }}
                                    disabled={timer !== 0}
                                    onClick={() => {
                                        USDTtoggleModal()
                                    }}
                                >
                                    {t('matrix.IUnderstand')} {timer > 0 && timer}
                                </Button>
                            </div> */}
                        </ModalBody>
                    </Modal>
                    : ''
                }



                {/* {nav_Check === 'Contest' &&
                    <ContestAcocunts />
                } */}
            </Container>
        </Container >
    )
}
export default MT5account