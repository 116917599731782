import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import App from './App'
import Authentication from './components/auth'
import ConfigDB from './data/customizer/config'
import { routes } from './route'
import ForgotPassword from './components/forgot_password'
import SignUpForm from './components/auth/sign_up'
import SweetAlert from 'sweetalert2'

import UserService from './network/services/user'
// import EventService from './network/services/event'
// import CurrencyService from './network/services/currency'
// import PromotionService from './network/services/promotion'
import PAMMService from './network/services/pammManager'
// import DepositCurrencyService from './network/services/depositCurrency'
// import PaymentService from './network/services/payment'
// import AccountService from './network/services/account'
// import AnnouncementService from './network/services/announcement'
// import NotificationService from './network/services/notification'

import { useTranslation } from 'react-i18next'

import VimeoPublic from './components/vimeo_public'

const AppWrapper = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [anim, setAnim] = useState('')
  const [notLoggedIn, setNotLoggedIn] = useState(null)
  const showAnnouncement = useSelector((state) => state.announcement.showAnnouncement)
  const user = useSelector((state) => state.user)
  const animation = localStorage.getItem('animation') || ConfigDB.data.router_animation || 'fade'

  const { t, i18n } = useTranslation()
  const [languageSelected, setLanguageSelected] = useState('en')

  const language = sessionStorage.getItem('language')

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    sessionStorage.setItem('language', language)
  }

  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false)

  const languageToggle = () => setLanguageDropdownOpen((prevState) => !prevState)
  

  const init = async () => {
    setAnim(animation)
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed']
    console.disableYellowBox = true

    const storedJwt = localStorage.getItem('token')
    const storedUser = localStorage.getItem('user')

    try {
      if (storedJwt != null && storedUser != null) {
        const updatedUser = await UserService.getMyself()



        dispatch({ type: 'SET_USER', user: updatedUser.user })
        dispatch({ type: 'SET_AGENT', partners: updatedUser.partners })
        dispatch({ type: 'SET_PROFILE', profile: updatedUser.profile ?? {} })

        if (updatedUser.verifications) {
          dispatch({ type: 'SET_VERIFICATIONS', verifications: updatedUser.verifications })
        }

        if (updatedUser.user?.auth_required) {
          dispatch({ type: 'SET_TWOFA', twoFA: updatedUser.user.auth_required }) // set true
        }

        if (updatedUser.credit_cards) {
          dispatch({ type: 'SET_CREDITCARDS', credit_cards: updatedUser.credit_cards })
        }

        if (updatedUser.banks) {
          dispatch({ type: 'SET_BANKS', banks: updatedUser.banks })
        }
        // console.log('updatedUser',updatedUser)


        const pammManagerResponse = await PAMMService.getAll()
        dispatch({ type: 'SET_PAMM_MANAGER', promotions: pammManagerResponse.pamms })


        setNotLoggedIn(false)
        dispatch({ type: 'SET_ACTIVE_MENU', path: location.pathname })

      } else {
        setNotLoggedIn(true)
      }
    } catch (error) {
      setNotLoggedIn(storedJwt == null)

      const errorCode = error.code
      const errorTranslate = error.translate_params
      SweetAlert.fire({
        showClass: {
          popup: 'animate__animated animate__backInUp'
        },
        hideClass: {
          popup: 'animate__animated animate__backOutDown'
        },
        title: t(errorCode, errorTranslate),
        icon: 'error'
      })
    }
  }

  // anytime we access the site
  useEffect(() => {
    init()
    // eslint-disable-next-line

  }, [])

  // on notLoggedIn changed
  useEffect(() => {
    if (notLoggedIn != null) {
      if (notLoggedIn) {
        if (
          location.pathname !== '/auth' &&
          location.pathname !== '/forgot_password' &&
          location.pathname !== '/sign_up' &&
          !location.pathname.includes('/vimeoPublic')
        ) {
          history.push('/auth')
        }
      } else if(!user?.profile?.hasOwnProperty('id')){
        history.push('/verify-details')
      }
    }
    // eslint-disable-next-line
  }, [notLoggedIn])

  return (
    <>
      {notLoggedIn != null ? (
        <Switch>
          {!notLoggedIn ? (
            <App>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                render={() => {
                  return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
                }}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/auth`}
                render={() => {
                  return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
                }}
              />

              <TransitionGroup>
                {routes.map(({ path, Component }) => (
                  <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`}>
                    {({ match }) => (
                      <CSSTransition
                        in={match != null}
                        timeout={100}
                        classNames={anim}
                        unmountOnExit
                        exit={false}
                      >
                        <div>
                          <Component />
                        </div>
                      </CSSTransition>
                    )}
                  </Route>
                ))}
              </TransitionGroup>
            </App>
          ) : (
              <>
                {/* 中文切换 */}
                {/* {!location.pathname.includes('/vimeoPublic') &&
                <Dropdown
                isOpen={languageDropdownOpen}
                onMouseLeave={() => setLanguageDropdownOpen(false)}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '30px',
                  paddingRight: '30px',
                  backgroundColor: '#fff'
                }}
              >
                <DropdownToggle
                  onMouseEnter={() => setLanguageDropdownOpen(true)}
                  id="langugeDropdown"
                >
                  <img
                    src={LanguageIcon}
                    alt="languageIcon"
                    width="18px"
                    height="18px"
                    className="m-r-5"
                  />
                  {language === 'en' && 'EN'}
                  {language === 'zh' && 'ZH'}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => changeLanguage('en')}>English1</DropdownItem>
                  <DropdownItem onClick={() => changeLanguage('zh')}>中文简体</DropdownItem>
                </DropdownMenu>
              </Dropdown>
                } */}


                <Route path={`${process.env.PUBLIC_URL}/auth`} component={Authentication} />
                <Route
                  path={`${process.env.PUBLIC_URL}/forgot_password`}
                  component={ForgotPassword}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/vimeoPublic/:id`}
                  component={VimeoPublic}
                />

                <Route path={`${process.env.PUBLIC_URL}/sign_up`} component={SignUpForm} />
              </>
            )}
        </Switch>
      ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh'
            }}
          >
            <Spinner />
          </div>
        )}
    </>
  )
}

export default AppWrapper
