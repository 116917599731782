import Authentication from '../components/auth'
// import DemoFree from '../components/auth/verifyAuth/createFreeDemo'

// import Financial from '../components/financial'
// import DemoAccounts from '../components/demo'
// import DemoAccountRegistration from '../components/demo/register'
// import LiveAccounts from '../components/live'
// import LiveAccountRegistration from '../components/live/register'
// import CompetitionAccount from '../components/competition_account'

import Monitoring from '../components/monitoring'
// import Partner from '../components/partner'
// import Platform from '../components/platforms'
// import RiskManagement from '../components/risk'
// import CustomerSupport from '../components/support'
// import SingleNotification from '../components/notification/singleNoti'
// import Faqs from '../components/faqs'
import User from '../components/user'

// import PaymentPendingCallback from '../components/payment/pendingpage.jsx'
// import AppPaymentRedirectingPage from '../components/payment/app_redirecting_page.jsx'
// import SupportReply from '../components/support/reply'

// import Promotion from '../components/promotion'
// import PromotionLanding from '../components/promotion/promotionLanding'

// import MyPammAccount from '../components/mypamm_account'
import Pamm from '../components/pamm/'
import PammManagerStatistic from '../components/pamm/PammManagerStatistic'
import PammManagerFollow from '../components/pamm/PammManagerFollow'

// import PammSubscription from '../components/pammSubscription'

// import Event from '../components/event/'
// import Carnival from '../components/carnival/index'
// import CampusEvent from '../components/event/campusCompetition'
// import DemoContestEvent from '../components/event/demoContest2022'
// import Contests from '../components/event/contests/'

// import RoadToChamption from '../components/event/openCompetition'

// import ReferralFriend from '../components/referral_friend'
import NewDashboard from '../components/newDashboard'

// import WelcomeBonus from '../components/auth/verifyAuth/verifySocial'
import VerifyDetails from '../components/auth/verifyAuth/verifyPersonal'
// import CreateFirstMT5 from '../components/auth/verifyAuth/createFirstMT5'
// import VerifyKYC from '../components/auth/verifyAuth/verifyKYC'

// import Notification from '../components/notification'
import MT5account from '../components/MT5account'

import MyCourses from '../components/courses/myCourses'
import Courses from '../components/courses'
import Chapter from '../components/courses/chapter'

// import Vouchers from '../components/vouchers'
// import Wallet from '../components/wallet'
// import WalletDeposit from '../components/wallet/wallet/deposit'
// import WalletMT5Deposit from '../components/wallet/wallet/MT5deposit'
// import WalletWithdrawal from '../components/wallet/wallet/withdrawal'
// import WalletMT5Withdrawal from '../components/wallet/wallet/MT5withdraw'
// import WalletTransfer from '../components/wallet/wallet/transfer'
// import WalletIBTransfer from '../components/wallet/wallet/IBtransfer'

// import CheckIn from '../components/checkin'
// import { Check } from 'react-feather'
import Language from '../components/language'
// import Calendar from '../components/calendar'
import VeDiscord from '../components/ve_discord/'
import VeDiscordSubscribe from '../components/ve_discord/subscribeForm'

import VeDiscordPaymentCallback from '../components/ve_discord/payment/successPage.jsx'
import VeDiscordPaymentFailCallback from '../components/ve_discord/payment/failPage.jsx'

export const routes = [
  { path: `${process.env.PUBLIC_URL}/auth`, Component: Authentication },
  // { path: `${process.env.PUBLIC_URL}/dashboard`, Component: Dashboard },

  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: NewDashboard },
  // { path: `${process.env.PUBLIC_URL}/financial`, Component: Financial },
  // { path: `${process.env.PUBLIC_URL}/partner`, Component: Partner },
  // { path: `${process.env.PUBLIC_URL}/risk`, Component: RiskManagement },
  { path: `${process.env.PUBLIC_URL}/monitoring`, Component: Monitoring },
  // { path: `${process.env.PUBLIC_URL}/platform`, Component: Platform },
  // { path: `${process.env.PUBLIC_URL}/support`, Component: CustomerSupport },
  // { path: `${process.env.PUBLIC_URL}/faqs`, Component: Faqs },
  // { path: `${process.env.PUBLIC_URL}/support/:id`, Component: SupportReply },
  { path: `${process.env.PUBLIC_URL}/account`, Component: User },
  // { path: `${process.env.PUBLIC_URL}/demo`, Component: DemoAccounts },
  // { path: `${process.env.PUBLIC_URL}/demo/register`, Component: DemoAccountRegistration },
  // { path: `${process.env.PUBLIC_URL}/live`, Component: LiveAccounts },
  // { path: `${process.env.PUBLIC_URL}/competition_account`, Component: CompetitionAccount },
  // { path: `${process.env.PUBLIC_URL}/live/register`, Component: LiveAccountRegistration },
  // { path: `${process.env.PUBLIC_URL}/notification/:id`, Component: SingleNotification },

  // {
  //   path: `${process.env.PUBLIC_URL}/checkIn`,
  //   Component: CheckIn
  // },
  {
    path: `${process.env.PUBLIC_URL}/ve_discord_payment/success`,
    Component: VeDiscordPaymentCallback
  },
  {
    path: `${process.env.PUBLIC_URL}/ve_discord_payment/fail`,
    Component: VeDiscordPaymentFailCallback
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/payment/pending`,
  //   Component: PaymentPendingCallback
  // },
  // {
  //   path: `${process.env.PUBLIC_URL}/app_payment_redirect`,
  //   Component: AppPaymentRedirectingPage
  // },

  // Promotion Route
  // { path: `${process.env.PUBLIC_URL}/promotion`, Component: Promotion },
  // { path: `${process.env.PUBLIC_URL}/promotion/:id`, Component: PromotionLanding },

  //PAMM Subscriptions
  // { path: `${process.env.PUBLIC_URL}/mySubscription`, Component: PammSubscription },

  //PAMM STATS && PAMM subscribe
  // { path: `${process.env.PUBLIC_URL}/mypamm_account`, Component: MyPammAccount },
  { path: `${process.env.PUBLIC_URL}/veFund`, Component: Pamm },
  { path: `${process.env.PUBLIC_URL}/veFund/:id/statistic`, Component: PammManagerStatistic },
  { path: `${process.env.PUBLIC_URL}/veFund/:id/subscribe`, Component: PammManagerFollow },

  // { path: `${process.env.PUBLIC_URL}/event`, Component: Event },
  // { path: `${process.env.PUBLIC_URL}/event/campus_competition`, Component: CampusEvent },
  // { path: `${process.env.PUBLIC_URL}/event/demo_contest_2022`, Component: DemoContestEvent },
  // { path: `${process.env.PUBLIC_URL}/event/contests/:id`, Component: Contests },

  // { path: `${process.env.PUBLIC_URL}/referral_friend`, Component: ReferralFriend },
  // { path: `${process.env.PUBLIC_URL}/welcomeBonus`, Component: WelcomeBonus },
  { path: `${process.env.PUBLIC_URL}/verify-details`, Component: VerifyDetails },
  // { path: `${process.env.PUBLIC_URL}/create-first-MT5`, Component: CreateFirstMT5 },
  // { path: `${process.env.PUBLIC_URL}/verify-KYC`, Component: VerifyKYC },
  // { path: `${process.env.PUBLIC_URL}/event/road_to_champion`, Component: RoadToChamption },

  // { path: `${process.env.PUBLIC_URL}/carnival/`, Component: Carnival },

  // { path: `${process.env.PUBLIC_URL}/notification`, Component: Notification },
  { path: `${process.env.PUBLIC_URL}/my_courses`, Component: MyCourses },
  { path: `${process.env.PUBLIC_URL}/courses`, Component: Courses },
  { path: `${process.env.PUBLIC_URL}/courses/:id/chapters`, Component: Chapter },
  // { path: `${process.env.PUBLIC_URL}/vouchers`, Component: Vouchers },

  //free depoit account
  // { path: `${process.env.PUBLIC_URL}/demo_get`, Component: DemoFree},
  { path: `${process.env.PUBLIC_URL}/language`, Component: Language },
  { path: `${process.env.PUBLIC_URL}/tradeAccount`, Component: MT5account },

  // { path: `${process.env.PUBLIC_URL}/wallet`, Component: Wallet },
  // { path: `${process.env.PUBLIC_URL}/wallet/deposit`, Component: WalletDeposit },
  // { path: `${process.env.PUBLIC_URL}/wallet/MT5deposit`, Component: WalletMT5Deposit },
  // { path: `${process.env.PUBLIC_URL}/wallet/withdrawal`, Component: WalletWithdrawal },
  // { path: `${process.env.PUBLIC_URL}/wallet/MT5withdraw`, Component: WalletMT5Withdrawal },
  // { path: `${process.env.PUBLIC_URL}/wallet/transfer`, Component: WalletTransfer },
  // { path: `${process.env.PUBLIC_URL}/wallet/partner_transfer`, Component: WalletIBTransfer },
  // { path: `${process.env.PUBLIC_URL}/calendar`, Component: Calendar },
  { path: `${process.env.PUBLIC_URL}/ve_discord`, Component: VeDiscord },
  { path: `${process.env.PUBLIC_URL}/ve_discord/subscribe/:lectureName`, Component: VeDiscordSubscribe },


]
