import React, { useState, useEffect } from 'react'
import { Container, Spinner, Tooltip, Row, Col, Form, FormGroup, Input, Button, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { HelpCircle, AlertCircle } from 'react-feather'
import { useSelector } from 'react-redux'
import SweetAlert from 'sweetalert2'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import _ from 'lodash'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AuthService from '../../network/services/auth'
import UserService from '../../network/services/user'
import SettingService from '../../network/services/setting'
import VEBackground from './veSignUpBackground.png'

import 'animate.css'
import './index.scss'

import LanguageIcon from '../../language.png'


const SignUpForm = () => {

  const { t, i18n } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { control, register, handleSubmit, errors, reset, watch, clearErrors, setError } = useForm()
  const watchPassword = watch('password')
  const Year = new Date();

  const [logo, setLogo] = useState(null)
  const [email, setEmail] = useState('')
  const [referral, setReferral] = useState(null)
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [referralCode, setReferralCode] = useState(null)
  const [generalError, setGeneralError] = useState(null)

  const [isPammChecked, setIsPammChecked] = useState(false)
  const [isContestChecked, setIsContestChecked] = useState(false)
  const [isTradeChecked, setIsTradeChecked] = useState(false)
  const [isBusy, setIsBusy] = useState(false)

  const [atLeastOneChecked, setAtLeastOneChecked] = useState(false)

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toolTiptoggle = () => setTooltipOpen(!tooltipOpen);

  const [languageSelected, setLanguageSelected] = useState('en')

  const language = sessionStorage.getItem('language')

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    sessionStorage.setItem('language', language)
  }

  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false)

  const init = async () => {
    try {
      const result = await SettingService.getAll()
      if (result.settings) {
        let logo2 = result.settings.find((s) => {
          return s.handle === 'crm_logo_url'
        })

        setLogo(logo2 ?? {})
      } else {
        setLogo({})
      }
    } catch (error) {
      setLogo({})
    }
  }

  useEffect(() => {
    init()
  }, [])

  const submitRegistration = async (data) => {


    try {
      setAtLeastOneChecked(false)
      setIsBusy(true)

      let postData = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: email,
        mobile: data.mobile,
        country: data.country,
        auth_code: data.auth_code,
        password: data.password,
        password_confirmation: data.confirm_password,
        referral_code: referralCode ?? data.referral_code,
        // is_asic: data.is_asic,
        // is_interest_pamm: isPammChecked,
        // is_interest_contest: isContestChecked,
        // is_interest_trading: isTradeChecked,
        // is_app_registered: false
      }

      const result = await AuthService.register(postData)
      const RegisterData = await AuthService.login({
        email: email,
        password: data.password
      })

      if (result?.user?.id) {
        setIsBusy(true)
        resetForm()
        SweetAlert.fire({
          showClass: {
            popup: 'animate__animated animate__backInUp'
          },
          hideClass: {
            popup: 'animate__animated animate__backOutDown'
          },
          title: t("auth.RegisterSuccessfully"),
          icon: 'success'
        }).then(() => {

          if (RegisterData.success) {
            localStorage.setItem('token', RegisterData.data.jwt.token)
            localStorage.setItem('user', JSON.stringify(RegisterData.data.user))

            const getMe = async () => {
              const updatedUser = await UserService.getMyself()
              window.location.href = '/verify-details'

            }
            getMe()
          }
        })
      }
    } catch (error) {
      setGeneralError(t(error.message))
      setIsBusy(false)
    }

  }



  const verifyEmail = async () => {
    if (email == null || email === '') {
      setError('email', {
        type: 'manual',
        message: t("auth.emailIsRequire")
      })

    }

    if (referral === null || referral === '') {
      setError('referral_code', {
        type: 'manual',
        message: t("auth.ReferralIsRequire")
      })

    }

    try {
      const result = await UserService.verifyEmail({
        email: email
      })

      if (result.success) {
        const codeResult = await AuthService.sendVerification({
          email: email
        })

        if (codeResult.success) {
          setIsEmailVerified(true)
          clearErrors('email')
        } else {
          setError('email', {
            type: 'manual',
            message: codeResult.message ?? t("auth.pleseTryAgain")
          })
          throw codeResult
        }
      } else {
        setError('email', {
          type: 'manual',
          message: t("auth.EmailAlreadyTaken")
        })
        throw result
      }
    } catch (error) {
      console.log(error)
    }
  }

  const resetForm = () => {
    reset()
    setEmail('')
    setReferral(null)
    setGeneralError(null)
    setIsEmailVerified(false)
  }

  useEffect(() => {
    let referralCodeQuery = new URLSearchParams(location.search).get('referral_code')
    if (!_.isEmpty(referralCodeQuery)) {
      setReferralCode(referralCodeQuery)
      setReferral(referralCodeQuery)
    }
  }, [])

  return (
    <>
      {logo != null ? (
        < >


          {/* <Dropdown
            isOpen={languageDropdownOpen}
            onMouseLeave={() => setLanguageDropdownOpen(false)}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: '30px',
              paddingRight: '30px',
              backgroundColor: 'transparent',
              zIndex: '5'
            }}
          >
            <DropdownToggle
              onMouseEnter={() => setLanguageDropdownOpen(true)}
              id="langugeDropdown"
            >
              <img
                src={LanguageIcon}
                alt="languageIcon"
                width="18px"
                height="18px"
                className="m-r-5"
              />
              {language === 'en' && 'EN'}
              {language === 'zh' && 'ZH'}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => changeLanguage('en')}>English</DropdownItem>
              <DropdownItem onClick={() => changeLanguage('zh')}>中文简体</DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
          <Row >
            <Col >
              {/* <Row> */}
              <div className="mb-3" style={{ position: 'fixed', top: '20px', left: '20px', zIndex: '3' }}>
                {logo?.value && (
                  <Link to={`${process.env.PUBLIC_URL}/auth`}>
                    <img
                      src={require('../../assets/images/logo/ve-logo.png')}
                      // src={newLogo}
                      alt="logo"
                      style={{ maxHeight: 175, maxWidth: 175 }}
                    />
                  </Link>
                )}
              </div>



              <Row style={{ justifyContent: 'center', zIndex: '4', height: '100vh' }}>
                <div
                  className='d-none d-lg-block'
                  width='40vw'
                  height='100vh'

                  style={{
                    backgroundColor: 'white',
                    backgroundSize: 'cover',
                    backgroundImage: `url('${VEBackground}')`,
                    position: 'relative'
                  }}>

                  {/* <p style={{ zIndex: '3', color: 'white', fontSize: '28px', margin: '50px' }}>
              </p> */}

                  <div className="d-flex justify-content-start align-items-start " style={{ color: 'white', textAlign: 'left', width: '40vw', height: '100vh' }}>
                    <div style={{ margin: '75px', color: 'black' }}>
                      <h1 className='m-0'>Learn with</h1>
                      <h1 className='m-0'>Value Envision</h1>
                      <h2 className='m-0'>用心教育，正規交易</h2>
                    </div>
                  </div>

                </div>
                <Form
                  id="registerForm"
                  className="theme-form cont"
                  onSubmit={(e) => {
                    e.preventDefault()
                  }}
                  style={{ margin: 'auto', zIndex: '5', minHeight: '310px', borderRadius: '0px 0px 50px 0px', border: 'none' }}
                >

                  <h4>{t("auth.register")}</h4>

                  {!isEmailVerified ? (
                    <p style={{ color: '#858585' }}>
                      {t("auth.enterVerificationCode")}{' '}
                    </p>
                  ) : (
                      ''
                    )}

                  <Label className="col-form-label pt-1 pb-0">{t("auth.emailAddress")}</Label>
                  <FormGroup>
                    <Input
                      id="input-form"
                      style={{ backgroundColor: "#f8f8f8" }}
                      className="form-control borderInput"
                      type="text"
                      placeholder={t("auth.emailAddress")}
                      name="email"
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value)
                      }}
                      disabled={isEmailVerified ? true : false}
                      innerRef={register({ required: t("auth.emailIsRequire") })}
                      required
                    />
                    <span style={{ color: 'red' }}>{errors.email && errors.email.message}</span>
                  </FormGroup>

                  <Label className="col-form-label pt-1 pb-0">{t("auth.referralCode")} </Label>
                  <FormGroup>
                    <Input
                      id="input-form"
                      style={{ backgroundColor: "#f8f8f8" }}
                      className="form-control borderInput"
                      type="text"
                      placeholder={t("auth.referralCode")}
                      name="referral_code"
                      defaultValue={referralCode}
                      innerRef={register({ required: t("auth.ReferralIsRequire") })}
                      required
                      disabled={referralCode != null}
                      onChange={(event) => {
                        setReferral(event.target.value)
                      }}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.referral_code && errors.referral_code.message}
                    </span>
                  </FormGroup>


                  {isEmailVerified && referral != null ? (
                    <>
                      <Row form>
                        <Col md="12">

                          <Label className="col-form-label pt-1 pb-0">{t("auth.verificationCode")}</Label>

                          <FormGroup>
                            <Input
                              id="input-form"
                              style={{ backgroundColor: "#f8f8f8" }}
                              className="form-control borderInput"
                              type="text"
                              placeholder={t("auth.verificationCode")}
                              name="auth_code"
                              innerRef={register({
                                required: t("auth.verificationCode")
                              })}
                            />
                            <span style={{ color: 'red' }}>
                              {errors.auth_code && errors.auth_code.message}
                            </span>
                          </FormGroup>

                          {isEmailVerified ? (
                            <p className='m-0' style={{ color: 'green', fontSize: '12px' }}>
                              {t("auth.pleaseCheckSpam", { email })}
                            </p>
                          ) : (
                              ''
                            )}

                          <Label className="col-form-label pt-1 pb-0">{t("auth.yourName")}</Label>

                        </Col>

                        <Col md="6">
                          <FormGroup>
                            <Input
                              id="input-form"
                              style={{ backgroundColor: "#f8f8f8" }}
                              className="form-control borderInput"
                              type="text"
                              placeholder={t("auth.firstName")}
                              name="first_name"
                              innerRef={register({
                                required: t("auth.firstNameRequire")
                              })}
                            />
                            <span style={{ color: 'red' }}>
                              {errors.first_name && errors.first_name.message}
                            </span>
                          </FormGroup>

                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Input
                              id="input-form"
                              style={{ backgroundColor: "#f8f8f8" }}
                              className="form-control borderInput"
                              type="text"
                              placeholder={t("auth.lastName")}
                              name="last_name"
                              innerRef={register({ required: t("auth.lastNameRequire") })}
                            />
                            <span style={{ color: 'red' }}>
                              {errors.last_name && errors.last_name.message}
                            </span>
                          </FormGroup>
                        </Col>
                        {isEmailVerified ? (
                          <p style={{ color: 'red', fontSize: '12px', margin: ' 0px 0px 0px 5px' }}>
                            {t("auth.enterFirstNameLastName")}
                          </p>
                        ) : (
                            ''
                          )}
                      </Row>

                      <Label className="col-form-label pt-1 pb-0">{t("auth.phoneNumber")}</Label>
                      <FormGroup className="borderInput rounded">
                        <Controller
                          id="input-form"
                          name="mobile"
                          style={{ backgroundColor: "#f8f8f8" }}
                          control={control}
                          rules={{ required: t("auth.phoneRequire") }}
                          as={
                            <PhoneInput
                              id="input-form"
                              style={{ backgroundColor: "#f8f8f8" }}
                              country={'my'}
                              className="form-control "
                              inputStyle={{ width: '100%', backgroundColor: "#fefbf3" }}
                              dropdownStyle={{
                                maxHeight: 100
                              }}
                            />
                          }
                        />
                        {/* <PhoneInput value={mobile}  /> */}
                        <span style={{ color: 'red' }}>{errors.mobile && errors.mobile.message}</span>
                      </FormGroup>

                      <Label className="col-form-label pt-1 pb-0">{t("auth.nationality")}</Label>
                      <FormGroup>
                        <Input
                          id="input-form"
                          style={{ backgroundColor: "#f8f8f8" }}
                          className="form-control borderInput"
                          type="select"
                          name="country"
                          placeholder={t("auth.country")}
                          innerRef={register({ required: true })}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            {t("auth.selectCountry")}
                          </option>
                          <option value="Afganistan">Afghanistan</option>
                          <option value="Albania">Albania</option>
                          <option value="Algeria">Algeria</option>
                          <option value="American Samoa">American Samoa</option>
                          <option value="Andorra">Andorra</option>
                          <option value="Angola">Angola</option>
                          <option value="Anguilla">Anguilla</option>
                          <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                          <option value="Argentina">Argentina</option>
                          <option value="Armenia">Armenia</option>
                          <option value="Aruba">Aruba</option>
                          <option value="Australia">Australia</option>
                          <option value="Austria">Austria</option>
                          <option value="Azerbaijan">Azerbaijan</option>
                          <option value="Bahamas">Bahamas</option>
                          <option value="Bahrain">Bahrain</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Barbados">Barbados</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Belgium">Belgium</option>
                          <option value="Belize">Belize</option>
                          <option value="Benin">Benin</option>
                          <option value="Bermuda">Bermuda</option>
                          <option value="Bhutan">Bhutan</option>
                          <option value="Bolivia">Bolivia</option>
                          <option value="Bonaire">Bonaire</option>
                          <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                          <option value="Botswana">Botswana</option>
                          <option value="Brazil">Brazil</option>
                          <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                          <option value="Brunei">Brunei</option>
                          <option value="Bulgaria">Bulgaria</option>
                          <option value="Burkina Faso">Burkina Faso</option>
                          <option value="Burundi">Burundi</option>
                          <option value="Cambodia">Cambodia</option>
                          <option value="Cameroon">Cameroon</option>
                          <option value="Canada">Canada</option>
                          <option value="Canary Islands">Canary Islands</option>
                          <option value="Cape Verde">Cape Verde</option>
                          <option value="Cayman Islands">Cayman Islands</option>
                          <option value="Central African Republic">Central African Republic</option>
                          <option value="Chad">Chad</option>
                          <option value="Channel Islands">Channel Islands</option>
                          <option value="Chile">Chile</option>
                          <option value="China">China</option>
                          <option value="Christmas Island">Christmas Island</option>
                          <option value="Cocos Island">Cocos Island</option>
                          <option value="Colombia">Colombia</option>
                          <option value="Comoros">Comoros</option>
                          <option value="Congo">Congo</option>
                          <option value="Cook Islands">Cook Islands</option>
                          <option value="Costa Rica">Costa Rica</option>
                          <option value="Cote DIvoire">Cote DIvoire</option>
                          <option value="Croatia">Croatia</option>
                          <option value="Cuba">Cuba</option>
                          <option value="Curaco">Curacao</option>
                          <option value="Cyprus">Cyprus</option>
                          <option value="Czech Republic">Czech Republic</option>
                          <option value="Denmark">Denmark</option>
                          <option value="Djibouti">Djibouti</option>
                          <option value="Dominica">Dominica</option>
                          <option value="Dominican Republic">Dominican Republic</option>
                          <option value="East Timor">East Timor</option>
                          <option value="Ecuador">Ecuador</option>
                          <option value="Egypt">Egypt</option>
                          <option value="El Salvador">El Salvador</option>
                          <option value="Equatorial Guinea">Equatorial Guinea</option>
                          <option value="Eritrea">Eritrea</option>
                          <option value="Estonia">Estonia</option>
                          <option value="Ethiopia">Ethiopia</option>
                          <option value="Falkland Islands">Falkland Islands</option>
                          <option value="Faroe Islands">Faroe Islands</option>
                          <option value="Fiji">Fiji</option>
                          <option value="Finland">Finland</option>
                          <option value="France">France</option>
                          <option value="French Guiana">French Guiana</option>
                          <option value="French Polynesia">French Polynesia</option>
                          <option value="French Southern Ter">French Southern Ter</option>
                          <option value="Gabon">Gabon</option>
                          <option value="Gambia">Gambia</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Germany">Germany</option>
                          <option value="Ghana">Ghana</option>
                          <option value="Gibraltar">Gibraltar</option>
                          <option value="Great Britain">Great Britain</option>
                          <option value="Greece">Greece</option>
                          <option value="Greenland">Greenland</option>
                          <option value="Grenada">Grenada</option>
                          <option value="Guadeloupe">Guadeloupe</option>
                          <option value="Guam">Guam</option>
                          <option value="Guatemala">Guatemala</option>
                          <option value="Guinea">Guinea</option>
                          <option value="Guyana">Guyana</option>
                          <option value="Haiti">Haiti</option>
                          <option value="Hawaii">Hawaii</option>
                          <option value="Honduras">Honduras</option>
                          <option value="Hong Kong">Hong Kong</option>
                          <option value="Hungary">Hungary</option>
                          <option value="Iceland">Iceland</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="India">India</option>
                          <option value="Iran">Iran</option>
                          <option value="Iraq">Iraq</option>
                          <option value="Ireland">Ireland</option>
                          <option value="Isle of Man">Isle of Man</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Jamaica">Jamaica</option>
                          <option value="Japan">Japan</option>
                          <option value="Jordan">Jordan</option>
                          <option value="Kazakhstan">Kazakhstan</option>
                          <option value="Kenya">Kenya</option>
                          <option value="Kiribati">Kiribati</option>
                          <option value="Korea North">Korea North</option>
                          <option value="Korea Sout">Korea South</option>
                          <option value="Kuwait">Kuwait</option>
                          <option value="Kyrgyzstan">Kyrgyzstan</option>
                          <option value="Laos">Laos</option>
                          <option value="Latvia">Latvia</option>
                          <option value="Lebanon">Lebanon</option>
                          <option value="Lesotho">Lesotho</option>
                          <option value="Liberia">Liberia</option>
                          <option value="Libya">Libya</option>
                          <option value="Liechtenstein">Liechtenstein</option>
                          <option value="Lithuania">Lithuania</option>
                          <option value="Luxembourg">Luxembourg</option>
                          <option value="Macau">Macau</option>
                          <option value="Macedonia">Macedonia</option>
                          <option value="Madagascar">Madagascar</option>
                          <option value="Malaysia">Malaysia</option>
                          <option value="Malawi">Malawi</option>
                          <option value="Maldives">Maldives</option>
                          <option value="Mali">Mali</option>
                          <option value="Malta">Malta</option>
                          <option value="Marshall Islands">Marshall Islands</option>
                          <option value="Martinique">Martinique</option>
                          <option value="Mauritania">Mauritania</option>
                          <option value="Mauritius">Mauritius</option>
                          <option value="Mayotte">Mayotte</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Midway Islands">Midway Islands</option>
                          <option value="Moldova">Moldova</option>
                          <option value="Monaco">Monaco</option>
                          <option value="Mongolia">Mongolia</option>
                          <option value="Montserrat">Montserrat</option>
                          <option value="Morocco">Morocco</option>
                          <option value="Mozambique">Mozambique</option>
                          <option value="Myanmar">Myanmar</option>
                          <option value="Nambia">Nambia</option>
                          <option value="Nauru">Nauru</option>
                          <option value="Nepal">Nepal</option>
                          <option value="Netherland Antilles">Netherland Antilles</option>
                          <option value="Netherlands">Netherlands (Holland, Europe)</option>
                          <option value="Nevis">Nevis</option>
                          <option value="New Caledonia">New Caledonia</option>
                          <option value="New Zealand">New Zealand</option>
                          <option value="Nicaragua">Nicaragua</option>
                          <option value="Niger">Niger</option>
                          <option value="Nigeria">Nigeria</option>
                          <option value="Niue">Niue</option>
                          <option value="Norfolk Island">Norfolk Island</option>
                          <option value="Norway">Norway</option>
                          <option value="Oman">Oman</option>
                          <option value="Pakistan">Pakistan</option>
                          <option value="Palau Island">Palau Island</option>
                          <option value="Palestine">Palestine</option>
                          <option value="Panama">Panama</option>
                          <option value="Papua New Guinea">Papua New Guinea</option>
                          <option value="Paraguay">Paraguay</option>
                          <option value="Peru">Peru</option>
                          <option value="Phillipines">Philippines</option>
                          <option value="Pitcairn Island">Pitcairn Island</option>
                          <option value="Poland">Poland</option>
                          <option value="Portugal">Portugal</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Qatar">Qatar</option>
                          <option value="Republic of Montenegro">Republic of Montenegro</option>
                          <option value="Republic of Serbia">Republic of Serbia</option>
                          <option value="Reunion">Reunion</option>
                          <option value="Romania">Romania</option>
                          <option value="Russia">Russia</option>
                          <option value="Rwanda">Rwanda</option>
                          <option value="St Barthelemy">St Barthelemy</option>
                          <option value="St Eustatius">St Eustatius</option>
                          <option value="St Helena">St Helena</option>
                          <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                          <option value="St Lucia">St Lucia</option>
                          <option value="St Maarten">St Maarten</option>
                          <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                          <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                          <option value="Saipan">Saipan</option>
                          <option value="Samoa">Samoa</option>
                          <option value="Samoa American">Samoa American</option>
                          <option value="San Marino">San Marino</option>
                          <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                          <option value="Saudi Arabia">Saudi Arabia</option>
                          <option value="Senegal">Senegal</option>
                          <option value="Seychelles">Seychelles</option>
                          <option value="Sierra Leone">Sierra Leone</option>
                          <option value="Singapore">Singapore</option>
                          <option value="Slovakia">Slovakia</option>
                          <option value="Slovenia">Slovenia</option>
                          <option value="Solomon Islands">Solomon Islands</option>
                          <option value="Somalia">Somalia</option>
                          <option value="South Africa">South Africa</option>
                          <option value="Spain">Spain</option>
                          <option value="Sri Lanka">Sri Lanka</option>
                          <option value="Sudan">Sudan</option>
                          <option value="Suriname">Suriname</option>
                          <option value="Swaziland">Swaziland</option>
                          <option value="Sweden">Sweden</option>
                          <option value="Switzerland">Switzerland</option>
                          <option value="Syria">Syria</option>
                          <option value="Tahiti">Tahiti</option>
                          <option value="Taiwan">Taiwan</option>
                          <option value="Tajikistan">Tajikistan</option>
                          <option value="Tanzania">Tanzania</option>
                          <option value="Thailand">Thailand</option>
                          <option value="Togo">Togo</option>
                          <option value="Tokelau">Tokelau</option>
                          <option value="Tonga">Tonga</option>
                          <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                          <option value="Tunisia">Tunisia</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Turkmenistan">Turkmenistan</option>
                          <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                          <option value="Tuvalu">Tuvalu</option>
                          <option value="Uganda">Uganda</option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Erimates">United Arab Emirates</option>
                          <option value="United States of America">United States of America</option>
                          <option value="Uraguay">Uruguay</option>
                          <option value="Uzbekistan">Uzbekistan</option>
                          <option value="Vanuatu">Vanuatu</option>
                          <option value="Vatican City State">Vatican City State</option>
                          <option value="Venezuela">Venezuela</option>
                          <option value="Vietnam">Vietnam</option>
                          <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                          <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                          <option value="Wake Island">Wake Island</option>
                          <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                          <option value="Yemen">Yemen</option>
                          <option value="Zaire">Zaire</option>
                          <option value="Zambia">Zambia</option>
                          <option value="Zimbabwe">Zimbabwe</option>
                        </Input>
                        <span style={{ color: 'red' }}>
                          {errors.country && t("auth.countryRequire")}
                        </span>
                      </FormGroup>

                      {/* <Label className="col-form-label pt-1 pb-0">
                      {t("auth.license")}
                      <span style={{ color: "#C4A569" }} href="#" id="LicenseToolTip">
                        <HelpCircle width="15px" />
                      </span>
                    </Label>
                    <Tooltip id="LicenseToolTip" placement="right" isOpen={tooltipOpen} target="LicenseToolTip" toggle={toolTiptoggle} >
                      {t("auth.normalLicenseLeverage")} <br />
                      {t("auth.asicLicenseLeverage")}
                    </Tooltip> */}
                      {/* <FormGroup>
                      <Input
                        id="input-form"
                        style={{ backgroundColor: "#f8f8f8" }}
                        className="form-control borderInput"
                        type="select"
                        name="is_asic"
                        placeholder={t("auth.country")}
                        innerRef={register({ required: true })}
                        defaultValue="0"
                      >
                        <option value="" disabled>
                          {t("auth.selectLicense")}
                        </option>
                        <option value={0}>{t("auth.normalLicense")}</option>
                        <option value={1}>{t("auth.asicLicense")}</option>

                      </Input>
                      <span style={{ color: 'red' }}>
                        {errors.is_asic && t("auth.licenseRequire")}
                      </span>
                    </FormGroup> */}


                      <Label className="col-form-label pt-1 pb-0">{t("auth.password")}</Label>
                      <FormGroup>
                        <Input
                          id="input-form"
                          style={{ backgroundColor: "#f8f8f8" }}
                          className="form-control borderInput"
                          type="password"
                          placeholder={t("auth.password")}
                          name="password"
                          innerRef={register({
                            required: t("auth.passIsRequire"),
                            minLength: {
                              value: 8,
                              message: t("auth.passwordMinLength")
                            }
                          })}
                        />
                        <span style={{ color: 'red' }}>
                          {errors.password && errors.password.message}
                        </span>
                      </FormGroup>

                      <Label className="col-form-label pt-1 pb-0">{t("auth.confirmPassword")}</Label>
                      <div>
                        <FormGroup >
                          <Input
                            id="input-form"
                            style={{ backgroundColor: "#f8f8f8" }}
                            className="form-control borderInput"
                            type="password"
                            placeholder={t("auth.confirmPassword")}
                            name="confirm_password"
                            innerRef={register({
                              required: t("auth.confirmPasswordRequire"),
                              minLength: 8,
                              validate: (value) => {
                                return value === watchPassword || t("auth.passwordNoMatch")
                              }
                            })}
                          />
                          <span style={{ color: 'red' }}>
                            {errors.confirm_password && errors.confirm_password.message}
                          </span>
                        </FormGroup>
                      </div>

                      {/* <Label className="col-form-label pt-1 pb-0">What interest you to create an account?</Label>

                    <div style={{ marginLeft: '20px' }}>
                      <Input
                        innerRef={register({ required: false })}
                        type="checkbox"
                        id="isPamm"
                        name="is_interest_pamm"
                        value="is_interest_pamm"
                        onChange={() => setIsPammChecked(!isPammChecked)}
                      />
                      <Label for="isPamm">PAMM</Label>
                    </div>


                    <div style={{ marginLeft: '20px' }}>
                      <Input
                        innerRef={register({ required: false })}
                        type="checkbox"
                        id="isContest"
                        name="is_interest_contest"
                        value="is_interest_contest"
                        onChange={() => setIsContestChecked(!isContestChecked)}
                      />
                      <Label for="isContest">Contest</Label>
                    </div>

                    <div style={{ marginLeft: '20px' }}>
                      <Input
                        innerRef={register({ required: false })}
                        type="checkbox"
                        id="isTrade"
                        name="is_interest_trading"
                        value="is_interest_trading"
                        onChange={() => setIsTradeChecked(!isTradeChecked)}
                      />
                      <Label for="isTrade">Trade</Label>
                    </div>

                    {
                      atLeastOneChecked &&
                      <span style={{ color: 'red' }}>
                        Please select at least one option
                      </span>
                    } */}


                      {
                        errors.auth_code
                          || errors.first_name
                          || errors.last_name
                          || errors.mobile
                          || errors.country
                          || errors.password
                          || errors.confirm_password
                          // || atLeastOneChecked
                          ?
                          <div style={{ color: 'red', padding: '20px', border: '1px solid lightgrey', borderRadius: '15px', marginBottom: '20px' }}>
                            <p>

                              {errors.auth_code &&
                                <div style={{ display: 'flex' }}>
                                  <AlertCircle width="15px" style={{ color: 'red', marginRight: '5px' }} />
                                  <span>{errors.auth_code.message}</span>
                                  <br />
                                </div>
                              }

                              {errors.first_name &&
                                <div style={{ display: 'flex' }}>
                                  <AlertCircle width="15px" style={{ color: 'red', marginRight: '5px' }} />
                                  <span>{errors.first_name.message}</span>
                                  <br />
                                </div>
                              }

                              {errors.last_name &&
                                <div style={{ display: 'flex' }}>
                                  <AlertCircle width="15px" style={{ color: 'red', marginRight: '5px' }} />
                                  <span>{errors.last_name.message}</span>
                                  <br />
                                </div>
                              }

                              {errors.mobile &&
                                <div style={{ display: 'flex' }}>
                                  <AlertCircle width="15px" style={{ color: 'red', marginRight: '5px' }} />
                                  <span>{errors.mobile.message}</span>
                                  <br />
                                </div>
                              }

                              {errors.country &&
                                <div style={{ display: 'flex' }}>
                                  <AlertCircle width="15px" style={{ color: 'red', marginRight: '5px' }} />
                                  <span>{t("auth.countryRequire")}</span>
                                  <br />
                                </div>
                              }

                              {/* {atLeastOneChecked &&
                              <div style={{ display: 'flex' }}>
                                <AlertCircle width="15px" style={{ color: 'red', marginRight: '5px' }} />
                                <span>Please select at least one interest</span>
                                <br />
                              </div>

                            } */}

                              {errors.password &&
                                <div style={{ display: 'flex' }}>
                                  <AlertCircle width="15px" style={{ color: 'red', marginRight: '5px' }} />
                                  <span>{errors.password.message}</span>
                                  <br />
                                </div>
                              }


                              {errors.confirm_password &&
                                <div style={{ display: 'flex' }}>
                                  <AlertCircle width="15px" style={{ color: 'red', marginRight: '5px' }} />
                                  <span>{errors.confirm_password.message}</span>
                                  <br />
                                </div>
                              }

                            </p>
                          </div>
                          : ''
                      }

                      <Row>
                        <Col>
                          <Button
                            className="mt-3 mb-3"
                            color="primary btn-block"
                            onClick={() => handleSubmit(submitRegistration)()}
                            disabled={isBusy}
                          >
                            {isBusy ? 'loading...' : t("auth.signUp")}
                          </Button>
                        </Col>
                      </Row>

                      <span style={{ color: 'red' }}>{generalError != null && generalError}</span>
                    </>
                  ) : (
                      <Row>
                        <Col>
                          <Button
                            className="mt-3 mb-3"
                            color="primary btn-block"
                            onClick={() => {
                              verifyEmail()
                            }}
                          >
                            {t("auth.sendVerificationCode")}
                          </Button>
                        </Col>
                      </Row>

                    )}
                  <div style={{ padding: 3 }} />
                  <div className='d-flex justify-content-end'>
                    <p style={{ color: '#898989' }}>{t("auth.alreadyHaveAccount")}
                      <Link to={`${process.env.PUBLIC_URL}/auth`} href="#javascript">
                        <span className="ml-1" style={{ color: '#C4A569' }}>{t("auth.login")}</span>
                      </Link>
                    </p>
                  </div>

                  <div className='d-flex justify-content-end'>
                    <p style={{ color: '#898989' }}>Need any assistance?

                </p>
                <a href="https://wa.me/601120682128?text=Hi%2C%20I%20need%20assist%20on%20VE%20Connect" target="_blank"><img

                      src={require('../../assets/images/logo/whatsapp.png')}
                      // src={newLogo}
                      alt="logo"
                      style={{ maxHeight: 20, maxWidth: 20, margin: '0px 5px 0px 10px', cursor: 'pointer' }}
                    /></a>


                    <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@ve.education" target="_blank">
                      <img
                        onClick={() => {

                        }}
                        src={require('../../assets/images/logo/mail.png')}
                        // src={newLogo}
                        alt="logo"
                        style={{ maxHeight: 20, maxWidth: 20, margin: '0px 5px', cursor: 'pointer' }}
                      />
                    </a>
                  </div>
                </Form>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
          <>
            <Container
              style={{
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spinner />
            </Container>
          </>
        )}


      {/* <div style={{position:'fixed',bottom:'20px',right:'60px',zIndex:'1',fontSize:'16px'}}>
          
          <p style={{color:'black'}}>{t("footer.copyright")} {Year.getFullYear()} </p>
        </div> */}
      {/* <div style={{position:'fixed',bottom:'80px',right:'60px'}}>
          <img src={headphone} style={{right:'20px'}} alt='headphone'/>
        </div> */}
    </>

  )
}

export default SignUpForm
